.AssistanceDiscountsProgram {
  #mainColMain {
    > .row {
      margin-bottom: 0;
      @media (max-width: 767px) {
        margin-top: 0 !important;
      }
    }

    h2 {
      border: 0 !important;
      overflow: initial;
    }
    .charts h2 {
      margin-top: 0;
    }
    .mb-10 {
      margin-bottom: 10px;
    }
  }

  #leftMainRight {
    @media (max-width: 767px) {
      border-top: solid 1px $global_color_mediumGray;
    }

    .pageTitle {
      @media (max-width: 768px) {
        margin-left: 10px;
        margin-bottom: 30px !important;
      }
    }

    .notificationContainer {
      p, div {
        max-width: 100%;
        margin-bottom: 0;
      }

      @media (max-width: 768px) {
        padding: 0px 10px;
      }
    }

    #quickInfoBarWrapper {
      background-color: $global_color_skyBlue;
      padding: 35px 0 0;
      text-align: center;
      margin: 0;
    
      #quickInfoBar {
        margin: 0 auto;
        
        @media (min-width: 768px) {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(auto-fit, 56px 1fr);
          grid-auto-flow: row;
          padding-bottom: 25px;
        }

        @media (min-width: 992px) {
          grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
          grid-template-rows: 56px 1fr;
        }
          
        @media (min-width: 1292px) {
          grid-template-columns: repeat(auto-fit, minmax(125px, 200px));
          grid-template-rows: 56px 1fr;
          max-width: 1292px;
          width: fit-content;
        }

        dt {
          position: relative;
          padding: 30px 0 2px 0;
          margin: 0;
          font-size: 14px;
          line-height: 22px;
          color: #333;
          border: 0;

          svg {
            position: absolute;
            left: calc(50% - 15px);
            top: -10px;
            font-size: 30px;
            color: $global_color_primaryBlue;
          }

          @media (min-width: 768px) {
            grid-row: 1;
          }

          @media (max-width: 991px) {
            &:nth-of-type(n+5) {
              grid-row: 3;
              margin-top: 40px;
            }
          }
        }
        
        dd {
          padding: 0;
          position: relative;

          &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 25px;
          }

          @media (min-width: 768px) {
            margin: 0;
            grid-row: 2;
          }
          @media (max-width: 991px) {
            &:nth-of-type(n+5) {
              grid-row: 4
            }
          }
        }
        
        a {
          position: absolute;
          top: -42px;
          left: 0px;
        }

        #quickInfoApplyDetail {
          visibility: hidden;

          @media (max-width: 767px) {
            margin: 0;
            padding: 0;
            height: 0;
          }
        }

        #quickInfoApplyButton {
          @media (max-width: 767px) {
            bottom: 0;
            padding: 0;
            margin-top: 40px;
            
            &.fixed {
              position: fixed;
              z-index: 1000;
              margin: 0;
              width: 100%;
            }

            a {
              width: 100vw;
              max-width: 100%;
              top: unset;
              bottom: 0;
              left: 0;
              border: solid 10px $global_color_white;
              border-radius: 0px;
            }
          }
        }
      }
    }

    .programImagesContainer {
      margin: 0;
      padding: 0;
      text-align: center;

      img {
        float: left;
        max-width: calc(50% - 5px);
        margin: 0 5px 0 0;
      }
      img:nth-child(2) {
        margin: 0 0 0 5px;
      }
      img:only-child {
        float: none;
        max-width: 100%;
        margin: 0 auto;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      @media (min-width: 768px) {
        margin-top: 45px;
      }
    }

    .programContentContainer {
      @media (min-width: 768px) {
        margin-top: 65px;
        margin-bottom: 0;
      }

      #applyButtonWrapper 
      {
        @media (max-width: 767px) {
          display: none;
        }
        
        small {
          display: block;
          margin-top: 5px;
          font-size: 12px;
          color: $global_color_darkGray;
        }
      }

      .reportsSection {
        float: none;
        width: initial;
        padding-top: 10px !important;
        margin-top: 90px !important;
        margin-bottom: 0px;
        margin-right: 0;
        margin-left: 0;
      }

      .reportsRow:last-child, 
      .scrollEnd, 
      div:last-child, 
      p:last-child, 
      ul:last-child, 
      li:last-child  {
        margin-bottom: 0;
      }

      .accordionWrapper p:last-child {
        margin-bottom: 30px;
      }

    }

    #navBarWrap {
      width: fit-content;
      margin: 0 auto;
    }

    .scrollSpyContainer, 
    .relatedProgramsContainer > section {
      max-width: 932px;
      margin: 0 auto 0;

      .charts {
        h2 {    
          svg {
            margin-right: 15px;
            color: $global_color_bronze;
          }
        
          @media (max-width: 767px) {
            margin-left: 10px;
          }
        }
      }

      @media (max-width: 767px) { 
        .reportsRow {
          margin-left: 10px;
          margin-right: 10px;
        }
        .reportsRow.MainColumnInsertComponent {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }

    .MainColumnTwoColumnComponent {
      .mainCol {
        margin: 0;
      }

      .mainColumnTwoColumn, .twoColLeft, .twoColRight {
        margin: 0;
        padding: 0;
      }
      .twoColLeft {
        @media (min-width: 992px) {
          padding-right: 10px;
        }
      }
      .twoColRight {
        @media (min-width: 992px) {
          padding-left: 10px;
        }
      }
    }
    
    .QuoteComponent {
      margin: 0 0 25px 0;
      .mainColumn_Quote {
        padding: 0;
      }
      .quoteBy {
        display: block;
        float: none;
      }
      .textOnly {
        text-align: center;
      }
    }

    .relatedProgramsContainer {
      background-color: $global_color_lightWhite;
      padding: 30px 0 60px;

      > section {
        padding-left: 10px;
      }

      h2 {
        padding: 0 !important;
      }
      @media (min-width: 768px) {
        > section {
          padding-left: 0px;
        }
      }

      div {
        margin-bottom: 0;
      }
      .cardContainerBodyCopy {
        margin-top: 20px;
        margin-left: 0;
      }
    }

    .ctaApplyContainer {
      background-color: $global_color_skyBlue;
      text-align: center;
      padding: 15px 0 50px;
      display: none;

      .callToActionColorBlockWrapper {
        display: inline-block;
      }

      div {
        margin-bottom: 0;
      }

      @media (min-width: 768px) {
        display: block;
      }
    }

    .relatedProgramsContainer, .ctaApplyContainer, #quickInfoBarWrapper {
      @media (min-width: 768px) {
        margin: 0 calc(50% - 50vw) !important;
        width: auto;
      }
    }

    // .VideoExternalComponent {
    //   iframe {
    //     width: 100% !important;
    //     height: 100% !important;
    //     max-width: unset;
    //   }
    // }

    .table-responsive  {
      border: 0; 
      @media (min-width: 768px) {
        table {
          width: 50%;
        }
      }
    }
  }

  #leftMainRight #RelatedPrograms {

    background-color: #f1f1f1;
    width: 100vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 20px 10px 0 !important;

    @media (min-width: 768px) {
      width: auto;
      margin-left: calc(50% - 50vw) !important;
      margin-right: calc(50% - 50vw) !important;
      padding: 50px 0 60px 0 !important;
    }

    .charts {
      h2 {
        margin-bottom: 0;
        margin-left: 0;
      }
    }
    .reportsRow {
      h2 {
        display: none !important;
      }
    }
    .charts,
    .reportsRow {
      margin: 0;

      @media (min-width: 768px) {
        max-width: 750px;
        margin: 0 auto;
      }
      @media (min-width: 992px) {
        max-width: 932px;
      }

    }
    .cardContainerBodyCopy {
      display: none;
      @media (min-width: 768px) {
        margin-top: 20px;
        display: block;
      }

      p {
        margin-bottom: 30px !important;
      }
    }
  }

  #mainColContent .programContentContainer {
    margin-bottom: 0;
  }
}
