﻿/*Color Blocks
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/

/**Orphaned Color Block Page Titles**/
.container.empty #leftMainRight {
    padding-top: 40px;
}

.container.empty #mainColMain {
    padding-bottom: 0px;
}

.container.empty #mainColMain > div {
    margin-bottom: 0px;
}

.container.empty #mainColMain h1 {
    margin-bottom: 0px;
}

.container.empty.noTitle {
    display: none;
}

/*Color Block General Styles
______________________________________________________________________*/
.colorBlock:not(.SearchCollectionFormContainerComponent):not(.SearchCollectionFormComponent) {
    padding: 40px 0px;
}

.colorBlock.MainColumnTwoColumnComponent button.btn,
.colorBlock.MainColumnTwoColumnComponent a.btn {
    padding: 10px 20px;
    font-size: $global_fontSize_default;
    font-weight: bold;
    border-radius: 0px;
    margin-top: 20px;
}

.colorBlock.MainColumnTwoColumnComponent .featuredPage button.btn,
.colorBlock.MainColumnTwoColumnComponent .featuredPage a.btn {
    background: $global_color_white none repeat scroll 0 0;
    color: $global_color_black;
}

.colorBlock.MainColumnTwoColumnComponent .featuredPage button.btn:hover,
.colorBlock.MainColumnTwoColumnComponent .featuredPage a.btn:hover {
    background: $global_color_black none repeat scroll 0 0;
    color: $global_color_white;
}

/*.colorBlock.MainColumnTwoColumnComponent .featuredPage button.btn::after,
.colorBlock.MainColumnTwoColumnComponent .featuredPage a.btn::after {
    content: "";
    font-family: "fontAwesome";
    margin-left: 0;
}*/

.colorBlock.MainColumnTwoColumnComponent h2 {
    margin-bottom: 30px;
}

.colorBlock.MainColumnTwoColumnComponent h2,
.colorBlock.MainColumnTwoColumnComponent h2 a {
    /*font-size: 2rem;*/
    display: inline-block;
}

.MainColumnInsertComponent .colorBlock h2 {
    margin-left: 20px;
}

.colorBlockQuote {
    display: table;
    width: 100%;
}

.quoteBody {
    font-family: 'Merriweather', serif;
    font-size: 1.4em;
    width: 95%;
    color: $global_color_black;
    padding: 10px;
    float: left;
}

.quoteBody .quoteBy {
    font-size: 0.9m;
    margin-top: 20px;
}

.quoteBody .quoteBy a,
.quoteBody .quoteBy a:active,
.quoteBody .quoteBy a:visited {
    color: $global_color_black;
    text-decoration: underline;
}

.colorBlockQuote .quoteBody.White,
.colorBlockQuote .quoteBody.White .quoteBy a,
.colorBlockQuote .quoteBody.White .quoteBy a:active,
.colorBlockQuote .quoteBody.White .quoteBy a:visited,
.colorBlockQuoteWide .quoteBody.White,
.colorBlockQuoteWide .quoteBody.White .quoteBy a,
.colorBlockQuoteWide .quoteBody.White .quoteBy a:active,
.colorBlockQuoteWide .quoteBody.White .quoteBy a:visited,
.colorBlock_Quote .quoteBody.White,
.colorBlock_Quote .quoteBody.White .quoteBy a,
.colorBlock_Quote .quoteBody.White .quoteBy a:active,
.colorBlock_Quote .quoteBody.White .quoteBy a:visited,
.colorBlock_QuoteWide .quoteBody.White,
.colorBlock_QuoteWide .quoteBody.White .quoteBy a,
.colorBlock_QuoteWide .quoteBody.White .quoteBy a:active,
.colorBlock_QuoteWide .quoteBody.White .quoteBy a:visited {
    color: $global_color_white;
}

.colorBlockQuote .quoteBody.Gray,
.colorBlockQuote .quoteBody.Gray .quoteBy a,
.colorBlockQuote .quoteBody.Gray .quoteBy a:active,
.colorBlockQuote .quoteBody.Gray .quoteBy a:visited,
.colorBlockQuoteWide .quoteBody.Gray,
.colorBlockQuoteWide .quoteBody.Gray .quoteBy a,
.colorBlockQuoteWide .quoteBody.Gray .quoteBy a:active,
.colorBlockQuoteWide .quoteBody.Gray .quoteBy a:visited,
.colorBlock_Quote .quoteBody.Gray,
.colorBlock_Quote .quoteBody.Gray .quoteBy a,
.colorBlock_Quote .quoteBody.Gray .quoteBy a:active,
.colorBlock_Quote .quoteBody.Gray .quoteBy a:visited,
.colorBlock_QuoteWide .quoteBody.Gray,
.colorBlock_QuoteWide .quoteBody.Gray .quoteBy a,
.colorBlock_QuoteWide .quoteBody.Gray .quoteBy a:active,
.colorBlock_QuoteWide .quoteBody.Gray .quoteBy a:visited {
    color: $global_color_mediumGray;
}

.colorBlockQuote .quoteBody.DarkGray,
.colorBlockQuote .quoteBody.DarkGray .quoteBy a,
.colorBlockQuote .quoteBody.DarkGray .quoteBy a:active,
.colorBlockQuote .quoteBody.DarkGray .quoteBy a:visited,
.colorBlockQuoteWide .quoteBody.DarkGray,
.colorBlockQuoteWide .quoteBody.DarkGray .quoteBy a,
.colorBlockQuoteWide .quoteBody.DarkGray .quoteBy a:active,
.colorBlockQuoteWide .quoteBody.DarkGray .quoteBy a:visited,
.colorBlock_Quote .quoteBody.DarkGray,
.colorBlock_Quote .quoteBody.DarkGray .quoteBy a,
.colorBlock_Quote .quoteBody.DarkGray .quoteBy a:active,
.colorBlock_Quote .quoteBody.DarkGray .quoteBy a:visited,
.colorBlock_QuoteWide .quoteBody.DarkGray,
.colorBlock_QuoteWide .quoteBody.DarkGray .quoteBy a,
.colorBlock_QuoteWide .quoteBody.DarkGray .quoteBy a:active,
.colorBlock_QuoteWide .quoteBody.DarkGray .quoteBy a:visited {
    color: $global_color_charcoal;
}

.colorBlockQuoteWide.colorBlock:not(.SearchCollectionFormContainerComponent):not(.SearchCollectionFormComponent) {
    display: table;
    padding: 26px 160px;
}

.QuoteComponentList.colorBlock:not(.SearchCollectionFormContainerComponent):not(.SearchCollectionFormComponent) {
    padding: 10px;
}

.QuoteComponent.colorBlock:not(.SearchCollectionFormContainerComponent):not(.SearchCollectionFormComponent) {
    padding: 10px;
}

.colorBlock blockQuote {
    border: none;
    padding-left: 10px;
    font-size: inherit;
}

.colorBlock cite {
    display: block;
}

// 09/17/24 Can we remove this?
/*.colorBlock .quoteMessage {
    line-height: 45px;
    background: $global_color_white;
    border-radius: 10px;
    padding-left: 40px;
    box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.5);
}

.colorBlock .quoteBody.White .quoteMessage {
    background: $global_color_white;
}

.colorBlock .quoteMessageNoImage::before {
    display: block;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -20px;
    top: 20px;
    color: inherit;
}

.colorBlock .quoteMessage::before {
    content: "";
    position: absolute;
    right: 100%;
    top: 55px;
    width: 0;
    height: 0;
    left: -15px;
    z-index: 1;
    border-top: 23px solid $global_color_transparent;
    border-right: 36px solid $global_color_white;
    border-bottom: 23px solid $global_color_transparent;
}

.colorBlock .quoteBody.White .quoteMessage::before {
    border-right: 36px solid $global_color_white;
}

.colorBlock .quoteMessageNoImage::after {
    display: block;
    content: "\201D";
    font-size: 80px;
    position: absolute;
    right: -20px;
    bottom: 20px;
    color: inherit;
}
*/
.colorBlock.MainColumnTwoColumnComponent {
    .quoteWrapper {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 30px;
    }

    @media (min-width: 992px) and (max-width: 1292px) {
        .twoColLeft, .twoColRight{
            width: 100%;
        }
    }
}

#mainColMain .mainColumnTwoColumn {
    .twoColLeft, .twoColRight {
        .quoteSideBarContainer.sidebarBackgroundColor {
            background-color: transparent;
        }
    }
}

@media (max-width: 575px) {
    .mainColumnTwoColumnColorBlock_Quote {
        .quoteWrapper {
            .quoteImage {
                display: none;
            }
        }
    }
}

/*Mobile support for quote containers*/
@media (min-width: 286px) and (max-width: 767px) {
    /*remove the quote arrow*/
    .colorBlock .quoteMessage::before {
        border-top: none;
        border-right: none;
        border-bottom: none;
    }
}

.colorBlock.colorBlockQuoteWide .textOnly {
    padding: 0 20px;
}

.colorBlock .quoteImageContainer {
    border: solid $global_color_black 1px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    width: 310px;
    height: 100%;
    line-height: 100%;
    margin: auto;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
    z-index: 2;
}

.QuoteComponent, .QuoteComponentList, .mainColumnTwoColumnColorBlock_Quote, #mainColMain .mainColumnTwoColumn {

    &.colorBlock:not(.SearchCollectionFormContainerComponent):not(.SearchCollectionFormComponent) {
        display: table;
        padding: 0;
        width: 100%;
    }

    &.colorBlock {

        .quote22 {
            display: table;

            &.colorBlock_Quote {
                width: 100%;

                &.quoteBody {

                    &.White {
                        color: $global_color_white;

                        .quoteBy {
                            a {
                                color: $global_color_white;

                                &:active {
                                    color: $global_color_white;
                                }

                                &:visited {
                                    color: $global_color_white;
                                }
                            }
                        }
                    }

                    &.Gray {
                        color: $global_color_mediumGray;

                        .quoteBy {
                            a {
                                color: $global_color_mediumGray;

                                &:active {
                                    color: $global_color_mediumGray;
                                }

                                &:visited {
                                    color: $global_color_mediumGray;
                                }
                            }
                        }
                    }

                    &.DarkGray {
                        color: $global_color_charcoal;

                        .quoteBy {
                            a {
                                color: $global_color_charcoal;

                                &:active {
                                    color: $global_color_charcoal;
                                }

                                &:visited {
                                    color: $global_color_charcoal;
                                }
                            }
                        }
                    }
                }

                &.textOnly {
                    padding: 49px 0 50px 0;
                    display: table;
                    text-align: center;

                    .quoteWrapper {
                        margin: auto;
                        width: 750px;

                        .quoteBody {
                            color: inherit;

                            .quoteIcon {
                                margin-bottom: 7px;
                            }

                            .quoteMessageNoImage {
                                padding: 0;

                                .quoteBy {
                                    margin-top: 24px;
                                }
                            }
                        }
                    }
                }

                &.image {
                    padding: 50px 0 50px 0;

                    .quoteWrapper {
                        margin: auto;
                        width: 850px;

                        .quoteBody {
                            width: 580px;
                            float: left;

                            .quoteIcon {
                                margin-bottom: 6px;
                            }

                            .quoteBy {
                                margin-top: 25px;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 991px) {

            .quote22 {

                &.colorBlock_Quote {

                    &.textOnly {
                        padding-bottom: 49px;

                        .quoteWrapper {
                            width: 80%;
                        }
                    }

                    &.image {

                        .quoteWrapper {
                            width: 90%;

                            .quoteImage {
                                margin-left: 20px;
                            }

                            .quoteBody {
                                width: 58%;

                                .quoteIcon {
                                    margin-bottom: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media(max-width: 767px) {

            .quote22 {

                &.colorBlock_Quote {

                    &.image {

                        .quoteWrapper {

                            .quoteBody {
                                width: 44%;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 575px) {

            .quote22 {

                &.colorBlock_Quote {

                    &.textOnly {
                        padding-bottom: 50px;

                        .quoteIcon {
                            margin-bottom: 6px;
                        }
                    }

                    &.image {
                        padding-bottom: 45px;

                        .quoteWrapper {
                            display: table;
                            text-align: center;
                            width: 80%;

                            .quoteImage {
                                padding: 0;
                                margin: auto;
                                float: unset;
                                margin-bottom: 29px;
                            }

                            .quoteBody {
                                width: 100%;
                                text-align: left;

                                .quoteIcon {
                                    margin-bottom: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.padTop {

        .quote22 {
            display: table;

            &.mainColumn_Quote {

                &.textOnly {
                    padding: 49px 0 50px 0;
                    display: table;

                    &.rightColAdjust {

                        .quoteWrapper {
                            width: auto;

                            .quoteBody {
                                width: 99%;
                            }
                        }
                    }

                    .quoteWrapper {
                        width: 850px;
                        margin-bottom: 0 !important;

                        .quoteBody {
                            color: inherit;
                            margin-bottom: 0 !important;

                            .quoteIcon {
                                margin-bottom: 6px;
                            }

                            blockquote {
                                font-size: inherit;
                                border: none;

                                &.quoteMessageNoImage {
                                    padding: 0;

                                    .quoteBy {
                                        margin-top: 24px;
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    &.leftColAdjust {

                        .quoteWrapper {
                            width: 100%;

                            .quoteBody {
                                width: 99%;
                            }
                        }
                    }
                }

                &.image {
                    padding: 50px 0 50px 0;

                    &.rightColAdjust {
                        margin-bottom: 0 !important;

                        .quoteWrapper {
                            width: auto;
                            margin-bottom: 0 !important;

                            .quoteImage {
                                margin: 0 30px 0 0;
                            }

                            .quoteBody {
                                margin-bottom: 0 !important;
                            }
                        }

                        &.leftColAdjust {

                            .quoteWrapper {

                                .quoteImage {
                                    margin-bottom: 29px;
                                }

                                .quoteBody {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    &.leftColAdjust {

                        .quoteWrapper {
                            width: auto;

                            .quoteImage {
                                margin-bottom: 29px;
                            }
                        }
                    }

                    .quoteWrapper {
                        margin: auto;
                        width: 850px;

                        .quoteImage {
                            height: 210px !important;
                        }

                        .quoteBody {
                            width: 580px;
                            float: left;

                            .quoteIcon {
                                margin-bottom: 7px;
                            }

                            blockquote {
                                font-size: inherit;
                                line-height: inherit;

                                &.quoteMessage {

                                    .quoteBy {
                                        margin-top: 24px;
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 1291px) {

            .quote22 {

                &.image {

                    &.rightColAdjust {

                        .quoteWrapper {

                            .quoteBody {
                                width: 51%;
                            }
                        }
                    }
                }
            }
        }


        @media (max-width: 991px) {

            .quote22 {

                &.rightColAdjust {
                    padding-bottom: 50px !important;

                    .quoteWrapper {

                        .quoteImage {
                            margin: 0 30px 0 0 !important;
                        }

                        .quoteBody {

                            .quoteIcon {
                                margin-bottom: 6px;
                            }
                        }
                    }
                }

                &.mainColumn_Quote {

                    &.textOnly {

                        .quoteWrapper {
                            width: 99%;

                            .quoteBody {
                                .quoteIcon {
                                    margin-bottom: 7px;
                                }
                            }
                        }
                    }

                    &.image {
                        margin-bottom: 0 !important;
                        padding-bottom: 50px;

                        .quoteWrapper {
                            width: auto;

                            .quoteBody {
                                width: 51%;
                                margin-bottom: 0 !important;

                                .quoteIcon {
                                    margin-bottom: 6px;
                                }
                            }
                        }

                        &.leftColAdjust {

                            .quoteWrapper {

                                .quoteBody {
                                    width: 51% !important;
                                }
                            }

                            &.rightColAdjust {

                                .quoteWrapper {

                                    .quoteImage {
                                        margin-bottom: 29px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 575px) {

            .quote22 {

                &.rightColAdjust {
                    padding-bottom: 44px !important;

                    .quoteWrapper {

                        .quoteImage {
                            margin: 0 0 29px 0 !important;
                        }
                    }

                    &.textOnly {
                        padding-bottom: 50px !important;
                    }

                    &.image {
                        padding-bottom: 45px !important;

                        .quoteBody {
                            width: auto !important;
                        }

                        &.rightColAdjust {

                            &.leftColAdjust {

                                .quoteWrapper {

                                    .quoteBody {
                                        width: 100% !important;
                                    }
                                }
                            }
                        }
                    }
                }

                &.mainColumn_Quote {

                    &.textOnly {

                        .quoteWrapper {

                            .quoteBody {
                                .quoteIcon {
                                    margin-bottom: 6px;
                                }
                            }
                        }
                    }

                    &.image {
                        padding-bottom: 45px;

                        &.leftColAdjust {

                            .quoteWrapper {

                                .quoteBody {
                                    width: 100% !important;
                                }
                            }
                        }

                        .quoteWrapper {
                            display: table;
                            margin-bottom: 0 !important;

                            .quoteImage {
                                padding: 0;
                                margin: auto;
                                float: unset;
                                margin-bottom: 29px;
                            }

                            .quoteBody {
                                width: 100%;
                                text-align: left;

                                .quoteIcon {
                                    margin-bottom: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .quoteImage {
        width: 240px;
        height: 210px;
        margin-right: 30px;
        float: left;
    }

    .quoteBody {
        padding: 0;
        width: 100%;
        font-family: $global_fontFamily_default;
        font-size: 16px;
        color: inherit;

        .quoteIcon {
            color: $global_color_bronze;
            width: 25px;
            height: 25px;
            text-align: center;
            font-family: $global_fontFamily_default;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 15px;
        }

        blockquote {
            margin: 0;

            &:before {
                content: none;
                position: inherit;
                border: none;
            }

            &.quoteMessage {
                line-height: inherit;
                background: none;
                border: none;
                padding: 0;
                box-shadow: none;
            }

            .quoteBy {
                font-style: inherit;
            }
        }
    }

    .quoteSideBarContainer {
        padding: 20px;
        font-family: $global_fontFamily_default;
        font-size: 16px;

        &.sidebarBackgroundColor {
            background-color: $global_color_lightWhite;
        }

        blockquote {
            padding: 0;
            margin: 0;
            font-size: inherit;
            border: none;

            .quoteBy {
                margin-top: 23px;
                display: block;
                font-style: normal;
            }
        }

        .quoteIcon {
            color: $global_color_bronze;
            width: 25px;
            height: 25px;
            margin-bottom: 14px;
            display: table;
            font-size: 20px;
        }
    }
}

/*Color Block Base Colors
______________________________________________________________________*/
.colorBlock.Charcoal,
.ParallaxComponentList.colorBlock .parallaxCopyContainer.Charcoal,
.ParallaxComponent.colorBlock .parallaxCopyContainer.Charcoal {
    background: $global_color_charcoal;
    color: $global_color_white;
}

.colorBlock.Gray,
.ParallaxComponentList.colorBlock .parallaxCopyContainer.Gray,
.ParallaxComponent.colorBlock .parallaxCopyContainer.Gray {
    background: $global_color_mediumGray;
    color: $global_color_white;
}

.colorBlock.LightBlue,
.colorBlock_Quote.LightBlue,
.ParallaxComponentList.colorBlock .parallaxCopyContainer.LightBlue,
.ParallaxComponent.colorBlock .parallaxCopyContainer.LightBlue {
    background: #e1f5fe;
    color: $global_color_black;
}

.colorBlock.White,
.colorBlock_Quote.White,
.ParallaxComponentList.colorBlock .parallaxCopyContainer.White,
.ParallaxComponent.colorBlock .parallaxCopyContainer.White,
.ParallaxComponent.colorBlock .parallaxCopyContainer {
    border-top: 1px solid $global_color_lightGray;
    background: $global_color_white;
    color: $global_color_black;
}

.colorBlock.LightGray,
.colorBlock_Quote.LightGray,
.ParallaxComponentList.colorBlock .parallaxCopyContainer.LightGray,
.ParallaxComponent.colorBlock .parallaxCopyContainer.LightGray {
    background: $global_color_lightWhite;
    color: $global_color_black;
}

.colorBlock.Green,
.ParallaxComponentList.colorBlock .parallaxCopyContainer.Green,
.ParallaxComponent.colorBlock .parallaxCopyContainer.Green {
    background: $global_color_green;
    color: $global_color_white;
}

.colorBlock.Navy,
.ParallaxComponentList.colorBlock .parallaxCopyContainer.Navy,
.ParallaxComponent.colorBlock .parallaxCopyContainer.Navy {
    background: #0D223F;
    color: $global_color_white;
}

.colorBlock.DarkGray,
.colorBlock_Quote.DarkGray,
.ParallaxComponentList.colorBlock .parallaxCopyContainer.DarkGray,
.ParallaxComponent.colorBlock .parallaxCopyContainer.DarkGray,
.parallaxCopyContainer.DarkGray .parallaxCopy * {
    background-color: #515151;
    color: $global_color_white;
}

.ParallaxComponentList.colorBlock, .ParallaxComponent.colorBlock {
    margin-left: auto;
    margin-right: auto;
}

/* Color Block - Call To Action */
.colorBlock[id*="CalltoAction"] {
    background-color: #fff;
    display: block;

    .callToActionColorBlockWrapper {
        color: #000;
        display: block;
        height: initial;
        padding: 20px 0px;
        text-align: center;

        > * {
            max-width: 850px;
            display: block;
            margin: 0px auto;
        }
    }

    .callToActionTitle {
        color: #000;
        /*font-size: 2rem;*/
        margin-bottom: 15px;

        @media(min-width: 768px) {
            /*font-size: 2rem;*/
            margin-bottom: 10px;
            margin-top: -3px;
        }
    }

    .htmlBlock {
        font-size: 1em;
        margin-bottom: 22px !important;
        padding: 0px 10px 0px 10px;

        > *:last-child {
            margin-bottom: 0px;
        }
    }

    .moreInformationLink {
        margin-bottom: 0px;

        @media(max-width: 767px) {
            padding: 0px 15px;
        }
    }

    .callToActionLink {
        color: #fff;
        font-size: 1em;
        transition: all .3s;
        margin-bottom: 0px;
        border-width: 1px;
        white-space: normal !important; /* fixes bootstrap's inability to wrap extra long buttons */
        &:hover {
            background-color: #333;
            border-color: #333;
        }

        .fa-arrow-up-right-from-square,
        .fa-file-arrow-down {
            margin-left: 10px;
        }
    }

    .thirdPartyPrivacyStatement {
        width: 100%;
        max-width: 550px;
        margin-top: 15px;
    }

    @media(max-width: 575px) {
        .thirdPartyPrivacyStatement {
            padding: 0px 10px 0px 10px
        }
    }
    /* Dark Gray */
    &.DarkGray {
        background-color: #515151 !important;

        .callToActionTitle,
        p {
            color: #fff !important;
        }

        .thirdPartyPrivacyStatement {
            color: $global_color_white;
        }

        .callToActionLink {
            color: #515151 !important;
            background-color: #fff;
            border-color: #fff;

            &:hover {
                color: #fff !important;
                background-color: $global_color_darkBlue !important;
                border-color: #fff;
            }
        }

        .htmlBlock a {
            color: #fff;
            font-weight: bold;
            text-decoration: underline;
        }

        .htmlBlock a:hover {
            color: $global_color_darkBlue !important;
            background-color: #fff;
        }

        .htmlBlock a:visited {
            color: #DBD5E9;
        }
    }
    /* Light Blue */
    &.LightBlue {
        background-color: #E1F5FE !important;

        .htmlBlock a {
            font-weight: bold;
            text-decoration: underline;
        }
    }
    /* Light Gray */
    &.LightGray {
        background-color: rgba(241,241,241, 1) !important;

        .htmlBlock a {
            font-weight: bold;
            text-decoration: underline;
        }
    }
    /* White*/
    &.White {
        background-color: #fff !important;

        .htmlBlock a {
            font-weight: bold;
            text-decoration: underline;
        }
    }
}

/* Color Block - Call To Action - MAC FIX */
.mac.notFF .colorBlock[id*="CalltoAction"] .callToActionTitle {
    margin-top: -15px;
    margin-bottom: 20px;

    @media(min-width: 768px) {
        margin-top: -10px;
        margin-bottom: 18px;
    }
}

/** END NEW COLOR BLOCK STYLING **/

/*Color Block Link Colors
______________________________________________________________________*/
.colorBlock.DarkGray.MainColumnTwoColumnComponent a {
    color: $global_color_white;
}

.colorBlock.DarkGray.MainColumnTwoColumnComponent button.btn,
.colorBlock.DarkGray.MainColumnTwoColumnComponent a.btn {
    background: $global_color_white;
    color: $global_color_black;
}

.colorBlock.LightBlue.MainColumnTwoColumnComponent button.btn,
.colorBlock.LightBlue.MainColumnTwoColumnComponent a.btn {
    background: $global_color_primaryBlue;
    color: $global_color_white;
}

.colorBlock.White.MainColumnTwoColumnComponent button.btn,
.colorBlock.White.MainColumnTwoColumnComponent a.btn {
    background: $global_color_primaryBlue;
    color: $global_color_white;
}

.colorBlock.MainColumnTwoColumnComponent button.btn:active,
.colorBlock.MainColumnTwoColumnComponent button.btn:hover,
.colorBlock.MainColumnTwoColumnComponent a.btn:active,
.colorBlock.MainColumnTwoColumnComponent a.btn:hover {
    background: $global_color_black;
    color: $global_color_white;
}

/*Color Block Headings
______________________________________________________________________*/
.colorBlock.LightBlue.TitledCollection h3.secondaryListTitle,
.colorBlock.White.TitledCollection h3.secondaryListTitle,
.colorBlock.TitledCollection h3.secondaryListTitle {
    color: $global_color_black;
}

.colorBlock.DarkGray.MainColumnTwoColumnComponent h2,
.colorBlock.DarkGray.MainColumnTwoColumnComponent h2 a,
.colorBlock.DarkGray.MainColumnTwoColumnComponent h3,
.colorBlock.DarkGray.MainColumnTwoColumnComponent h3 a,
.colorBlock.DarkGray.TitledCollection h3.secondaryListTitle {
    background: $global_color_transparent;
    color: $global_color_white;
}

/*.ColorBlockTilesetComponent.colorBlock h2 {
    font-size: 2rem;
}*/

.colorBlock.DarkGray.MainColumnTwoColumnComponent {
    a {
        background: $global_color_white;
        color: $global_color_primaryBlue !important;
    }
}
.colorBlock.DarkGray.MainColumnTwoColumnComponent {
    a:hover {
        color: $global_color_black;
        background: $global_color_white !important;
        text-decoration: none;
    }
}
.colorBlock.DarkGray.MainColumnTwoColumnComponent button.btn:active,
.colorBlock.DarkGray.MainColumnTwoColumnComponent button.btn:hover,
.colorBlock.DarkGray.MainColumnTwoColumnComponent a.btn:active,
.colorBlock.DarkGray.MainColumnTwoColumnComponent a.btn:hover {
    background: $global_color_black !important;
    color: $global_color_white !important;
}

.ColorBlockTilesetComponent.DarkGray.colorBlock h2,
.SingleRowTilesetComponent.DarkGray.colorBlock h2 {
    color: $global_color_white;
}

.ColorBlockTilesetComponent.White.colorBlock h2,
.ColorBlockTilesetComponent.LightBlue.colorBlock h2 {
    color: $global_color_black;
}
/*Color Block Blog Feeds
______________________________________________________________________*/
.colorBlock.CityLinkRSSComponent {

    .rssTitle, .rssDescription {
        margin-bottom: 30px;
    }

    .rssTitle a {
        font-weight: bold;

        &:hover {
            background: $global_color_primaryBlue;
            color: $global_color_white;
            text-decoration: none;
        }
    }

    .rssItem a {
        img {
            display: inline-block;
            height: auto;
            max-width: 100%;
        }
    }
    .rssDate {
        background: $global_color_white;
        color: $global_color_black;
    }
    .feedContainer.title_excerpt,
    .feedContainer.title,
    .feedContainer.title_fulldescription {
        margin-left: auto;
        margin-right: auto;
        max-width: 621px;
    }
}
.colorBlock.DarkGray.CityLinkRSSComponent {
    a {
        color: $global_color_white;
        text-decoration: underline;

        &:hover {
            background-color: $global_color_white;
            color: $global_color_primaryBlue !important;
            text-decoration: underline;
        }
    }

    .feedcard {
        svg {
            color: #ea5300;
        }
    }
}
.colorBlock.CityLinkRSSComponent .feedLinks,
.colorBlock.CityLinkRSSComponent .feedLinks a,
.colorBlock.MainColumnTwoColumnComponent .feedLinks,
.colorBlock.MainColumnTwoColumnComponent .feedLinks a {
    display: inline;
    margin-left: 5px;
    background: $global_color_transparent;
    font-weight: bold;

    &:hover {
        background: $global_color_primaryBlue;
        color: $global_color_white;
        text-decoration: none;
    }
}
.colorBlock.CityLinkRSSComponent h2 {
    display: inline-block;
    margin-bottom: 30px;
}
.colorBlock.DarkGray.MainColumnTwoColumnComponent .fa-rss,
.colorBlock.DarkGray.CityLinkRSSComponent .fa-rss {
    color: $global_color_white;
}

.colorBlock.MainColumnTwoColumnComponent .feedLinks a:hover .fa-rss {
    text-decoration: underline;
}

.colorBlock.MainColumnTwoColumnComponent .rssItem,
.colorBlock.MainColumnTwoColumnComponent .rssTitle {
    margin-bottom: 30px;
}

.colorBlock.MainColumnTwoColumnComponent .rssTitle,
.colorBlock.MainColumnTwoColumnComponent .rssTitle a {
    background: $global_color_transparent;
    font-weight: bold;
}

.colorBlock.DarkGray.MainColumnTwoColumnComponent .feedLinks a,
.colorBlock.DarkGray.MainColumnTwoColumnComponent .rssTitle,
.colorBlock.DarkGray.MainColumnTwoColumnComponent .rssTitle a,
.colorBlock.DarkGray.CityLinkRSSComponent .rssTitle a,
.colorBlock.DarkGray.CityLinkRSSComponent .feedContainer .feedTitleIconWrapper h2 a {
    color: $global_color_white;
    text-decoration: underline;
    display: inline-block;
}

.colorBlock.MainColumnTwoColumnComponent .rssDate {
    border: 1px solid $global_color_black;
    border-top: 5px solid $global_color_orange;
    background: $global_color_white;
    color: $global_color_black;
}

//Color Block Board Memeber
.colorBlock.DarkGray.MainColumnTwoColumnComponent h4.boardMemberName {
    color: $global_color_white;
}

// Color Block Vertical Timeline and Time Period Component
.colorBlock.DarkGray.MainColumnTwoColumnComponent .timePeriod .description,
.colorBlock.DarkGray.MainColumnTwoColumnComponent .timePeriodBlack .description {
    color: $global_color_black;
}

// Color Block Visual Highlight Component List
.colorBlock.DarkGray.MainColumnTwoColumnComponent .visualListContainer svg {
    color: $global_color_white !important;
}

// Color Block Twitter Component
.colorBlock.MainColumnTwoColumnComponent .twoColRight .twitterTile.taxonomyTile {
    height: 100%;
    max-width: 620px;
    width: 100%;
}

// Color Block Trumba Component
.colorBlock.DarkGray.MainColumnTwoColumnComponent .trumbacal div {
    background-color: $global_color_white;
}

// Color Block Board Member Component
.colorBlock.MainColumnTwoColumnComponent .boardMember div.boardMemberContent .toggleBio {
    color: $global_color_white;

    &:hover {
        color: $global_color_black;
        background: $global_color_white;
    }
}

.colorBlock.White.MainColumnTwoColumnComponent .boardMember div.boardMemberContent .toggleBio,
.colorBlock.LightBlue.MainColumnTwoColumnComponent .boardMember div.boardMemberContent .toggleBio,
.colorBlock.LightGray.MainColumnTwoColumnComponent .boardMember div.boardMemberContent .toggleBio {
    color: $global_color_black;

    &:hover {
        color: $global_color_white;
        background: $global_color_black;
    }
}

/*Color Block Accordion Components
______________________________________________________________________*/
.MainColumnAccordionListComponent.colorBlock .accordsFa {
    max-width: 850px;
}

.colorBlock.MainColumnTwoColumnComponent .accordion-toggle {
    display: block;
    margin-bottom: 0px;
    border-bottom: 1px solid $global_color_lightWhite;
}

.colorBlock.DarkGray.MainColumnAccordionListComponent h2 {
    color: $global_color_white;
}

.colorBlock.DarkGray.MainColumnTwoColumnComponent .accordion-toggle {
    border-top: none;
}

.colorBlock.MainColumnTwoColumnComponent .accordsFa,
.MainColumnAccordionListComponent.colorBlock .accordsFa {
    .accordion-toggle {
        background-color: $global_color_white;

        &:hover {
            background-color: #dad9d6;
        }
    }
}

.colorBlock.MainColumnAccordionListComponent .content *:not(.btn):not(.btn *),
.colorBlock.MainColumnTwoColumnComponent .accordionWrapper .content *:not(.btn):not(.btn *) {
    color: $global_color_black;
}

.colorBlock.MainColumnAccordionListComponent .accordionWrapper,
.colorBlock.MainColumnTwoColumnComponent .accordionWrapper {
    background-color: $global_color_white;
}

@media(max-width: 575px) {
    .colorBlock.MainColumnTwoColumnComponent .accordion-toggle,
    .colorBlock.MainColumnAccordionListComponent .accordion-toggle,
    #mainColMain h2.accordion-toggle {
        border-bottom: 1px solid $global_color_lightWhite !important;
    }
    // css for mobile view of accordion when it's inside a colorblock.
    .colorBlock.MainColumnAccordionListComponent.container {
        padding-right: 10px;
        padding-left: 10px;
    }
}

/*Report Highlight Component
_____________________________________________________________________*/
.colorBlock.MainColumnTwoColumnComponent {
    .reportHighlightIconDiv {
        display: inline-block;
        width: 100%;

        .left {
            display: flex;
            float: left;
            width: 100%;

            svg {
                color: $global_color_green;
                font-weight: 700;
                font-size: 14px;
                width: auto;
                height: 55px;
                margin: 1px 20px 0 0;
                float: left;
            }
        }

        .right {
            display: inline-block;
            margin-bottom: 30px;

            ol, ul {
                margin-top: 15px;
            }
        }

        h3 {
            margin-top: auto;
            margin-bottom: auto;
        }

        p {
            float: left;
            margin-top: 12px;
        }
    }

    .reportHighlightIconDiv {
        margin-bottom: 30px;
    }

    .reportHighlightIconComponentList.Green .left svg {
        color: $global_color_green !important;
    }

    .reportHighlightIconComponentList.Blue .left svg {
        color: $global_color_primaryBlue !important;
    }

    .reportHighlightIconComponentList.Gold .left svg,
    .reportHighlightIconComponentList.Bronze .left svg {
        color: $global_color_bronze !important;
    }

    .reportHighlightIconComponentList.Gray .left svg {
        color: $global_color_mediumDarkGray !important;
    }
}

/*Highlight Number List in Color Block
______________________________________________________________________*/
.colorBlock.NumberHighlightComponentList.highlightNumberBgColor {
    background-color: $global_color_lightWhite;
}

/*Two Column Image and Text Grid Component
_____________________________________________________________________*/
.TwoColumnImageandTextGridComponent.colorBlock {
    margin-bottom: -10px;

    .headTitle {
        margin-bottom: -12px;
    }

    .headDescription {
        margin-bottom: 36px;
    }

    .titleWithLink, .titleWithNoLink {
        margin-top: -1px;
    }

    .itemBody {
        margin-top: -11px;
        margin-bottom: 27px !important;
    }

    .twogridImage {
        max-height: 250px;
        min-height: 250px;
        width: 100%;
        object-fit: cover;
    }
}

@media screen and (min-width: 765px) and (max-width: 991px) {
    .TwoColumnImageandTextGridComponent.colorBlock.container {
        width: 750px;
    }
}

@media (max-width: 767px) {
    .TwoColumnImageandTextGridComponent.colorBlock.container {
        padding-left: 10px !important;
        padding-right: 2px !important;
    }
}

.colorBlock.DarkGray.TwoColumnImageandTextGridComponent {
    background-color: #515151;
    color: $global_color_white;
}

.colorBlock.TwoColumnImageandTextGridComponent a {
    font-weight: bold;
}

.colorBlock.DarkGray.TwoColumnImageandTextGridComponent h2,
.colorBlock.DarkGray.TwoColumnImageandTextGridComponent h3 {
    color: $global_color_white;
}

/*ScrollSpyContainer Component in Colorblock for CityWide-Portal & CityWide-Detail
__________________________________________________________________________________*/

.CityWideDetail .ScrollSpyContainerComponent.colorBlock,
.topTier .ScrollSpyContainerComponent.colorBlock {

    .servicesSection .leftWrapper .cardContainer .row {
        position: relative;
    }

    .servicesSection:nth-child(odd):not(.filteredContainer) {
        background-color: $global_color_lightWhite;
    }

    @media (max-width: 575px) {
        .servicesWrapper .tileCounter {
            display: none !important;
        }
    }
}


/*Color Block Titled Collections
______________________________________________________________________*/
.colorBlock.TitledCollection .taxonomyTile.navigationContact h3 {
    color: $global_color_primaryBlue;
    font-size: 18px;
    margin: 0px;
    font-family: $global_fontFamily_default;
}

.colorBlock.TitledCollection select {
    color: $global_color_black;
}
/*Color Block Search Forms
______________________________________________________________________*/
.colorBlock.SearchCollectionFormComponent .findWhatTitle {
    margin-bottom: 2px;
    text-align: center;
    width: 100%;
}

.colorBlock.DarkGray.SearchCollectionFormComponent.findWhatTitle {
    color: $global_color_white;
}

.colorBlock.DarkGray.SearchCollectionFormComponent *,
.colorBlock.LightBlue.SearchCollectionFormComponent *,
.colorBlock.White.SearchCollectionFormComponent *,
.colorBlock.LightGray.SearchCollectionFormComponent * {
    text-shadow: none;
    box-shadow: none;
}

.colorBlock.LightBlue.SearchCollectionFormComponent .findWhatTitle,
.colorBlock.LightBlue.SearchCollectionFormComponent .byWrapper,
.colorBlock.LightGray.SearchCollectionFormComponent .findWhatTitle,
.colorBlock.LightGray.SearchCollectionFormComponent .byWrapper,
.colorBlock.White.SearchCollectionFormComponent .findWhatTitle,
.colorBlock.White.SearchCollectionFormComponent .byWrapper {
    color: $global_color_black;
}

.colorBlock.LightBlue.SearchCollectionFormComponent .findByIndicator,
.colorBlock.White.SearchCollectionFormComponent .findByIndicator {
    text-shadow: 0px -1px 0px $global_color_black;
    margin-top: 1px;
}

.colorBlock.LightBlue.SearchCollectionFormComponent .findByInput input[type="text"],
.colorBlock.White.SearchCollectionFormComponent .findByInput input[type="text"] {
    border: 1px solid #888;
}

/*Color Block Topic Filter Components 
______________________________________________________________________*/
.colorBlock.TopicFilterComponent {
    padding-bottom: 80px !important;
    border-top: 1px solid $global_color_lightGray;
    border-bottom: 60px solid $global_color_darkGray;

    #taxonomyTermGroup {
        .checkbox {
            padding-right: 20px;
        }

        &:first-child {
            margin-top: 0px;
        }
    }

    .topicSubhead {
        margin-top: 10px;
    }

    .topics {
        padding: 0px;
    }
}

/*Parallax styles
______________________________________________________________________*/
.ParallaxComponentList.colorBlock:not(.SearchCollectionFormContainerComponent):not(.SearchCollectionFormComponent),
.ParallaxComponent.colorBlock:not(.SearchCollectionFormContainerComponent):not(.SearchCollectionFormComponent) {
    padding: 0;
}

.parallaxCopyContainer {
    min-height: 100px;
    width: 100%;
    background: $global_color_black;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.9);
}

.parallaxCopy {
    max-width: 1292px;
    padding: 40px 10px;
    color: $global_color_black;
    margin-right: auto;
    margin-left: auto;
}

.parallaxCopy a {
    text-decoration: underline;
    color: inherit;
}

.parallaxCopy.DarkGray p, .parallaxCopy.DarkGray h1, .parallaxCopy.DarkGray h2, .parallaxCopy.DarkGray h3,
.parallaxCopy.DarkGray ul, .parallaxCopy.DarkGray li, .parallaxCopy.DarkGray a {
    color: #333f48;
}

.parallaxCopy.White p, .parallaxCopy.White h1, .parallaxCopy.White h2, .parallaxCopy.White h3,
.parallaxCopy.White ul, .parallaxCopy.White li, .parallaxCopy.White a {
    color: $global_color_white;
}

/*Call to Action styles
______________________________________________________________________*/
*[id^="CalltoActionColorBlockComponent"] {
    display: table;
    width: 100%;
}

.callToActionColorBlockWrapper {
    display: table-cell;
    vertical-align: middle;
}

.callToActionColorBlockWrapper .callToActionTitle {
    border-bottom: none !important;
    margin-bottom: 9px;
    z-index: 100;
}

.callToActionColorBlockWrapper .htmlBlock {
    padding: 0px;
    margin-bottom: -10px !important;
    width:100%;
    z-index: 100;
}

.callToActionColorBlockWrapper .moreInformationLink {
    z-index: 100;
    margin-bottom: 13px;
}

.callToActionColorBlockWrapper .callToActionLink {
    font-size: 1em;
    font-weight: bold;
    color: $global_color_white;
    padding: 10px 16px !important;
    transition: all .3s;
    margin-bottom: 0px;
    border-width: 1px;
}

.callToActionColorBlockWrapper .thirdPartyPrivacyStatement {
    width: 100%;
    max-width: 550px;
    font-size: 12px;
    margin-top: -17px;
    margin-bottom: 10px;

    p {
        line-height: 1.1 !important;
        margin-bottom: 0px;
    }
}

.callToActionColorBlockWrapper .btn {
    border-color: $global_color_primaryBlue;
    background-color: $global_color_primaryBlue;
    color: $global_color_white;
}

.callToActionColorBlockWrapper .btn-ghost {
    border-color: $global_color_white;
    background-color: transparent;
    color: $global_color_white;
}

.callToActionColorBlockWrapper .btn:hover {
    background-color: $global_color_black;
    color: $global_color_white;
    border-color: $global_color_black;
}

.callToActionColorBlockWrapper .btn-ghost:hover {
    border-color: $global_color_white;
}

.moreInformationLink {
    a:hover {
        background-color: #333 !important;
    }
}

.callToActionColorBlockWrapper .backgroundImage {
    background-position: center center;
    background-size: cover;
    position: absolute;
    background-repeat: no-repeat;
}

/*Featured Page
______________________________________________________________________*/
.featuredPage {
    width: 100%;
    float: left;
    padding: 0 10px;
    font-size: 16px;
}

.featuredPage .featuredContent {
    float: left;
    display: block;
}

.featuredPage .featuredContent img {
    width: 300px;
    float: right;
    padding: 0 0 10px 10px;
}

.featuredPage .moreLink {
    text-align: center;
    width: 100%;
    font-weight: bold;
    margin: 30px 0;
    float: left;
    display: block;
}

.featuredPagePipe {
    border-right: solid 5px $global_color_white;
    padding-right: 5px;
}

.colorBlock.MainColumnTwoColumnComponent.paddingZero {
    padding: 0 !important;
}

.paddingZero .twoColLeft .featuredPage {
    padding: 40px 30px 0 10px;
}

.paddingZero .twoColRight .featuredPage {
    padding-left: 30px;
}


/*Colorblock Tile set*/
.fullWidth {
    width: 100%;
    margin: 10px;
}

.secondaryTiles .aTile {
    width: 630px;
    height: 310px;
}

/*Desktop*/
@media (min-width: 992px) and (max-width: 1291px) {
    .secondaryTiles .aTile {
        width: 630px;
        max-width: 310px;
    }
}

/*Tablet*/
@media (min-width: 768px) and (max-width: 991px) {
    .secondaryTiles .aTile {
        width: 48%;
        height: 150px;
    }
}

/*Mobile*/
@media (max-width: 767px) {
    .secondaryTiles .aTile {
        width: 310px;
        height: 150px;
    }

    .ScrollSpyContainerComponent.colorBlock.container {
        padding-left: 10px;
        padding-right: 10px;
    }
}

*[id^="colorBlockTiles"] .taxonomyTile {
    width: 310px;
}

/*A sized featured image settings start*/
.secondaryTiles .colorBlockATile, .tileSize_A .colorBlockATile {
    width: 630px;
    height: 310px;
    background-repeat: no-repeat;
    background-size: cover;
}

.secondaryTiles .colorBlockATile {
    position: absolute;
    left: 0px;
    top: 0px;
}

.tileSize_A .colorBlockATile {
    position: relative;
    left: 0px;
    top: 0px;
}

.tileSize_A .colorBlockATile.videoExternal {
    padding: 0;
    background-color: #000;
}

.embedVideo.seattleChannel {
    min-height: 338px;
    width: 100%;
    max-width: 600px;
    padding-left: 35px;
}

.embedVideo.vimeo, .embedVideo.youTube {
    height: 310px;
    width: 100%;
    max-width: 600px;
}

#rightCol {
    .embedVideo.seattleChannel {
        min-height: 190px;
    }

    .embedVideo.vimeo, .embedVideo.youTube {
        height: 190px;
    }
}

.VideoExternalComponent {
    .embedVideo.vimeo, .embedVideo.youTube, .embedVideo.seattleChannel {
        padding-left: 0px;
    }
}

#rightCol {
    .embedVideo.vimeo, .embedVideo.youTube, .embedVideo.seattleChannel {
        width: 100%;
    }
}

.tileSize_A.floatRight, .tileSize_B.floatRight {
    float: right;
    display: block;
    margin-right: 12px;
}

.tileSize_A.floatLeft, .tileSize_B.floatLeft, .taxonomyTile.floatLeft {
    float: left;
    display: block;
}

/*Desktop*/
@media (min-width: 992px) and (max-width: 1291px) {
    .secondaryTiles .colorBlockATile {
        width: 630px;
        max-width: 310px;
        height: 310px;
    }

    .tileSize_A.floatRight, .tileSize_B.floatRight {
        margin-right: 10px;
    }
}

/*Tablet*/
@media (min-width: 667px) and (max-width: 991px) {
    .secondaryTiles .colorBlockATile {
        width: 630px;
        height: 310px;
    }

    .tileSize_A.floatRight {
        float: left;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .tileSize_B.floatRight {
        margin-right: 110px;
    }
}

@media (min-width: 667px) and (max-width: 767px) {
    .tileSize_B.floatRight {
        float: left;
        margin-right: 0px;
    }
}

/*Mobile*/
@media (min-width: 286px) and (max-width: 666px) {
    *[id^="featuredTileA"] .secondaryList .secondaryTiles {
        max-height: 320px !important;
    }

    .secondaryTiles .colorBlockATile {
        width: 310px;
        height: 310px;
    }

    .tileSize_A .colorBlockATile, .tileSize_B .colorBlockBTile {
        width: 310px;
        height: 150px;
    }

    .tileSize_A .colorBlockATile.videoExternal {
        padding: 0;
    }

    .tileSize_A .colorBlockATile .embedVideo {
        width: 100%;
        height: 100%;
    }

    .embedVideo.seattleChannel {
        padding-left: 20px;
    }

    .tileSize_A.floatRight, .tileSize_B.floatRight {
        float: left;
    }

    .tileSize_B.floatRight {
        margin-right: 0px;
    }
}
/*A sized featured image settings end*/

/*B sized featured image settings start*/
.secondaryTiles .colorBlockBTile, .tileSize_B .colorBlockBTile {
    width: 310px;
    background-repeat: no-repeat;
    background-size: cover;
}

.secondaryTiles .colorBlockBTile {
    position: absolute;
    left: 0px;
    top: 0px;
}

.tileSize_B .colorBlockBTile {
    position: relative;
    left: 0px;
    top: 0px;
}

/*cta color block in maincol2col*/
.mainColumnTwoColumn {
    .callToActionColorBlockWrapper {
        text-align: left !important;
    }

    .callToActionTitle {
        border: none !important;
        margin-bottom: 9px !important;
    }

    .htmlBlock {
        font-size: 16px;
        width: 100% !important;
    }
}
// Call To Action In Main Column Two Column ColorBlock 
.colorBlock.MainColumnTwoColumnComponent .callToActionColorBlockWrapper {
    .callToActionTitle {
        font-size: 32px;
    }

    .thirdPartyPrivacyStatement {
        margin-top: 13px;
    }

    .callToActionLink.btn.btn-primary.btn-lg::after {
        content: none;
    }
}

.colorBlock.DarkGray.MainColumnTwoColumnComponent {
    .callToActionColorBlockWrapper .btn {
        border-color: $global_color_white;
    }
}

@media (max-width: 767px) {
    .colorBlock.MainColumnTwoColumnComponent {
        .callToActionColorBlockWrapper .callToActionTitle {
            font-size: 24px;
        }
    }
}

/*Desktop*/
@media (min-width: 992px) and (max-width: 1291px) {
    .secondaryTiles .colorBlockBTile {
        width: 630px;
        max-width: 310px;
        height: 310px;
    }
}

/*Tablet*/
@media (min-width: 667px) and (max-width: 991px) {
    .secondaryTiles .colorBlockBTile {
        width: 630px;
        height: 310px;
    }
}

/*Mobile*/
@media (min-width: 286px) and (max-width: 666px) {
    *[id^="featuredTileB"] .secondaryList .secondaryTiles {
        max-height: 320px !important;
    }

    .secondaryTiles .colorBlockBTile {
        width: 310px;
        height: 310px;
    }

    .embedVideo.vimeo, .embedVideo.youTube, .embedVideo.seattleChannel {
        height: 100%;
        padding-left: 0px;
    }

    .colorBlock.MainColumnXHTMLCopyComponent {
        .container .row {
            padding: 0px 10px;
        }
    }
}
/*B sized featured image settings end
    Colorblock Tile set
*/

/*Image in maincoltwocol colorblock*/
.colorBlock.MainColumnTwoColumnComponent img{
    max-width: 100%;
    height: auto;
}
