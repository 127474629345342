/*Department Styles
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////*/
/*Page Title*/
.department #mainColMain h1.pageTitle {
    color: $global_color_black;
    display: inline-block;
    font-size: 2.375rem;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 10px;
    max-width: 95%;

    &.blackText {
        background-color: $global_color_white;
        color: $global_color_black;
    }

    &.centered {
        padding: 0;

        @media (min-width: 768px) {
            width: 100%;
            max-width: 100%;
            text-align: center;
            margin-bottom: 30px;
        }
    }
}

/*Images*/
.department #mainColContent {

    img {
        min-width: 24px;
        min-height: 24px;
        max-width: 100%;
        height: auto;

        &.blockImg {
            margin-bottom: 5px;
        }
    }

    a {
        #rightCol {
            a {
                img {
                    min-width: 24px;
                    min-height: 24px;
                }
            }
        }
    }
}
.department.locationPage .featureWrapperShort {
    height: 350px;
}

/* Major Event */
.department {
    &.DepartmentHome {
        .container {
            &.banner {
                margin-top: 0;
            }
        }
    }

}

/*Container Spacing
______________________________________________________________________*/
#mainContent.topTier #secondaryList,
#mainColMain{
    padding-bottom: 100px
}

.deptHome #leftMainRight{
    margin: 60px auto 0px auto;
}


/***********************************************************************/
/***********************************************************************/
/* DELETE AFTER TOPNAV DROPDOWN RELEASE */
/* IT IS REDONE IN BEM STYLE */
/***********************************************************************/
/***********************************************************************/

/*Top Nav
______________________________________________________________________*/
.topNavSubContainer {
    display: none; /* hide subnav links in top nav temporary */
}

#deptTopNav {  
    background: $global_color_white;
    width: 100%;
    padding: 0px 0px 10px 0px;
    margin: 20px 0px 0px 0px;
    height: 30px;
    font-size: 14px;

    /* Resets */
    ul {
        padding-left: 0px;
    }
    li {
        list-style: none;
        display:flex;
    }
    a, a:hover {
        text-decoration: none;
        color: $global_color_black;
        font-weight: 700;
        display: flex;
    }
    /* End of Resets */

    /* Primary Nav */
    > ul {
        > li {
            float: left; 
            position: relative;
            margin-right: 25px;

            > a {
                padding: 0px 15px;
                border-bottom: 5px solid $global_color_white;
                position: relative;

                height: 30px;
                padding: 0px;
                transition:border-color .4s;

                &:hover {
                    border-color: $global_color_primaryBlue;
                }
            }

            &:hover .dropdown,
            &.open .dropdown {
                height: auto;
                opacity: 1;
                overflow: visible;
                
                li {
                    display: block;
                }
            }
            &.open .dropdown-toggle svg{
                transform: rotate(180deg);
            }
        }
    }
    /* End of Primary Nav */

}
/* Start of Top nav submenu 
______________________________________________________________________*/
#deptTopSubNav {
    background-color: $global_color_black !important;
    clear: both;
    text-transform: uppercase !important;
}

#deptTopSubNav ul {
    list-style-type: none;
}

#deptTopSubNav ul li {
    display: inline-block;
    padding: 20px 12px 14px 12px !important;
    width: auto;
}

#deptTopSubNav a {
    font-weight: 600;
    font-size: .85em;
    padding: 20px 12px 20px 12px;
    text-decoration: none;
    color: $global_color_white !important;
}

#deptTopSubNav a:hover {
    border-bottom: 6px solid $global_color_submenuhover;
}


#deptTopNav {  
    a, a:hover {
        text-decoration: none;
        color: $global_color_black;
        font-weight: 700;
        display: flex;
    }
}

#deptTopNav>ul>li>a:active,
#deptTopNav>ul>li>a.activePage,
#deptTopNav >ul>li>a:hover{
    border-bottom: 5px solid $global_color_primaryBlue;
}

/***********************************************************************/
/***********************************************************************/
/* END DELETE AFTER TOPNAV DROPDOWN RELEASE */
/***********************************************************************/
/***********************************************************************/




/* Start of Top Nav 
______________________________________________________________________*/

@mixin top-nav__reset-link {
    text-decoration: none;
    color: $global_color_black;
    font-weight: 700;
    display: flex;
}

.top-nav {
    background: $global_color_white;
    width: 100%;
    padding: 0px 0px 10px 0px;
    margin: 20px 0px 0px 0px;
    height: 30px;
    font-size: 14px;

    @at-root #{&}__list {
        padding-left: 0px;
    }

    @at-root #{&}__item {
        list-style: none;
        display: flex;
        float: left; 
        position: relative;
        margin-right: 25px;
    
        &:hover .top-subnav,
        &.open .top-subnav {
            visibility: visible;
            opacity: 1;
            overflow: visible;
            
            li {
                display: block;
            }
        }
        &.open .top-nav__chevron{
            transform: rotate(180deg);
        }
    }

    @at-root #{&}__link {
        @include top-nav__reset-link;

        padding: 0px 15px;
        border-bottom: 5px solid $global_color_white;
        position: relative;
        height: 30px;
        padding: 0px;
        transition:border-color .4s;
    
        &:hover, &.activePage {
            @include top-nav__reset-link;

            border-color: $global_color_primaryBlue;
        }
    }

    @at-root #{&}__toggle {
        background: $global_color_white;
        color: $global_color_primaryBlue;
        width: 20px;
        height: 20px;
        border: 0px;
        padding: 0px;
        margin-left: 4px;
        
        &:focus {
            outline: thin dotted;
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: -2px;
        }
    }

    @at-root #{&}__chevron {
        transition: all .5s;
    }
}

.top-subnav {
    padding-left: 0px;
    height: auto;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    position: absolute; 
    z-index: 5;
    top: 30px;
    left: 0px;
    padding-right: 180px;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: display .01s, border-color .5s, opacity .5s;

    @at-root #{&}__item {
        list-style: none;
        display: flex;
    }
    @at-root #{&}__link {
        @include top-nav__reset-link;

        background: $global_color_white;
        align-items: center; 
        padding: 10px 15px;
        min-width: 200px;
        width: 100%;
    
        + .top-subnav__excerpt { 
            visibility: hidden;
        }

        &:hover,
        &:focus {
            @include top-nav__reset-link;

            background: $global_color_lightWhite;
    
            + .top-subnav__excerpt { visibility: visible }
        }
    }
    @at-root #{&}__excerpt {
        background: $global_color_lightWhite;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 180px;
        min-height: 100%;
        display: flex;
        align-items: center; 
        justify-content: left;
        padding: 15px;
        color: $global_color_darkGray;
    }
}


/* Start of Top nav dropdown list/button 
______________________________________________________________________*/
.topNavUtilityLinks {
    float: right;
    height: 0;

    .topNavigation {

        &.dropDown, &.button {

            float: left;
            position: relative;
            top: -40px;
            margin-left: 10px;

            .btn {
                font-weight: bold;

                &.hasList {

                    &:focus {
                        outline: solid;
                        color: $global_color_linkBlue;
                    }

                    &.btn-primary {
                        &:focus {
                            outline: dotted;
                            color: $global_color_white;
                        }
                    }

                    svg {
                        position: relative;
                        top: 1px;
                        left: 3px;
                        font-size: 16px;
                    }
                }

                &:hover {
                    background-color: $global_color_primaryBlue;
                    border: solid 1px $global_color_white;
                    color: $global_color_white;
                    -webkit-box-shadow: 0px 0px 3px 2px rgba(0,70,173,0.2);
                    box-shadow: 0px 0px 3px 2px rgba(0,70,173,0.2);
                }

                &.btn-primary {
                    background-color: $global_color_primaryBlue;
                    border: solid 2px $global_color_primaryBlue;
                    color: $global_color_white;
                }

                &.btn-primary:hover {
                    background-color: #000;
                }

                &.btn-secondary {
                    background-color: $global_color_white;
                    border: solid 2px $global_color_primaryBlue;
                    color: $global_color_primaryBlue;

                    &:hover {
                        background-color: $global_color_black;
                        color: $global_color_white;
                    }
                } 
            }

            &.open {

                .btn {
                    box-shadow: 0px 0px 3px 2px rgba(0,70,173,0.2);
                    -webkit-box-shadow: 0px 0px 3px 2px rgba(0,70,173,0.2);
                }
            }
        }

        &.button {

            .btn {

                &.btn-primary {
                    border: solid 2px $global_color_primaryBlue;
                }

            }

        }

        .dropdown-menu {
            padding: 0;
            white-space: nowrap;
            box-shadow: 0px 0px 3px 2px rgba(0,70,173,0.2);
            -webkit-box-shadow: 0px 0px 3px 2px rgba(0,70,173,0.2);
        }

        .dropdown-item {
            float: left;
            padding: 0 20px;
            line-height: 40px;
            color: #333;
            font-weight: bold;
            width: 100%;
            display: block;

            &:hover {
                text-decoration: none;
                background-color: $global_color_lightWhite;
            } 
        }
        a.externalLink {
            svg {
                margin-left: 5px;                
            }
        }
    }
}

@media (max-width: 991px) {

    .topNavUtilityLinks {
        display: none;
    }

}

/* Start of Top nav submenu 
______________________________________________________________________*/
#deptTopSubNav {
    background-color: $global_color_black !important;
    clear: both;
    text-transform: uppercase !important;
}

#deptTopSubNav ul {
    list-style-type: none;
}

#deptTopSubNav ul li {
    display: inline-block;
    padding: 20px 12px 14px 12px !important;
    width: auto;
}

#deptTopSubNav a {
    font-weight: 600;
    font-size: .85em;
    padding: 20px 12px 20px 12px;
    text-decoration: none;
    color: $global_color_white !important;
}

#deptTopSubNav a:hover {
    border-bottom: 6px solid $global_color_submenuhover;
}


/*Department Footer
______________________________________________________________________*/
#departmentContactFooterWrapper {
    padding-bottom:0px;
}
#departmentContactFooter {
    padding:30px 10px;
}
#deptFootLogoTitle > img{
    padding-right: 10px;
}
span.footerSeattleText,
h1.footerSeattleText {
    margin:7px 0px -7px 0px;
    line-height: 1;
    font-size: 40px;
    font-family: $global_fontFamily_deptTitle;
    font-weight: bold;
    color: $global_color_black;
    display: block;
}
.deptFooterTitle a {
    color: $global_color_black;
    font-size: 18px;
    font-family: $global_fontFamily_default;
}
#deptFootContactWrapper {
    margin-right: 15px;
    text-align: right;
}
.footerSocialMediaWrapper > .socialMediaContactItem img, 
.contactTileSocialMediaWrapper > .socialMediaContactItem img {
    display: inline;
}
#deptFootContactWrapper .contactTileSocialMediaWrapper a{
    padding-right: 2px;
}  
/*Optional Department Sub Footers
______________________________________________________________________*/
/*Department Footer Links*/
#departmentFooterLinksNewsWrapper {
    background-color: $global_color_lightWhite;
    padding: 30px 0px;
}
#departmentFooterLinksNewsWrapper .deptFootLinkCol a{
    display: block;
    padding: 10px 0px 8px;
    color: $global_color_black;
    font-size:15px;
    line-height:17px;
}
#departmentFooterLinksNewsWrapper .deptFootLinkCol a:hover{
    color: $global_color_black;
}

#departmentFooterLinksNewsWrapper .h3TitleFiller{
    height: 26px;
}
/**Department Footer Links --- Short**/

/** legacy - TO BE CLEANED UP AFTER GO LIVE **/
deptFootLinksWrapperShort{
    background-color: $global_color_lightWhite;
    padding: 15px 0px;
}
#deptFootLinksWrapperShort ul{
    list-style:none;
    padding-left:0px;
}
#deptFootLinksWrapperShort ul li{
    float:left;
    margin:10px 60px 10px 0px;
}
#deptFootLinksWrapperShort ul li:last-child{
    margin-right:0px;
}    
#deptFootLinksWrapperShort ul li a{
    color: $global_color_black;
    font-family: $global_fontFamily_default;
    font-weight:600;
    font-size:16px;
    padding: 5px 10px 3px 10px;
    text-decoration:none;
}
#deptFootLinksWrapperShort ul li a:hover{
    color: $global_color_white;
    background: $global_color_primaryBlue;
    text-decoration:none;
}
/** END legacy - TO BE CLEANED UP AFTER GO LIVE **/

#deptFootLinksWrapperShort.aboutNav {
    background-color: $global_color_primaryBlue;
    padding: 4.5px 0 4.5px 0;

    ul{
        list-style: none;
        padding-left: 0px;
        position: relative;
        left: -9px;

        li { 
            float: left;
            margin: 10px 0;
            padding: 0 9px 0 9px;

            &:first-child {

                margin-right: -1px;
                border-right: solid 1px $global_color_white;
                padding-left: 0;

                a {
                    font-family: $global_fontFamily_default;
                    font-size: 32px;
                    position: relative;
                    top: -1px;
                    padding: 0 10px 2px 10px;
                }
            }

            a {
                color: $global_color_white;
                font-family: $global_fontFamily_body;
                font-weight: 600;
                font-size: 20px;
                padding: 5px 10px;
                text-decoration:none;
                position: relative;
                top: 10px;
            }

            a:hover{
                color: $global_color_primaryBlue;
                background: $global_color_white;
                text-decoration: none;
            }

            &:last-child{
                margin-right: 0px;
            }    
        }
    }
}

/*Department Footer Newsletter && Newsletter Signup*/
#deptFootNewsWrapper h3, .newsletterSignup h3 {
    display: inline-block;
}
.deptFootDeptNameNewsSubscribe, .listName {
    color: $global_color_mediumGray;
    display: block;
    font-family: $global_fontFamily_default;
    font-size: 18px;
    font-weight: 300;
    margin-top:-3px;
}
#deptFootNewsWrapper i.fa, .newsletterSignup i.fa {
    float: right;
    margin-top: 18px;
    font-size:30px;
    color: $global_color_black;
}
.newsletterSignup h3, .newsletterSignup i.fa {
    margin-top: 0px;
}
#deptFootNewsWrapper form, .newsletterSignup form {
    margin-top: 20px;
}
#deptFootNewsWrapper input[name="email"], .newsletterSignup input[name="email"] {
    line-height: 34px;
    padding-bottom: 3px;
    width: 245px;
    padding-left: 10px;
}
.newsletterSignup input[name="email"] {
    width: 226px;
}
#deptFootNewsWrapper button, .newsletterSignup button {
    background-color: #00469f;
    display: inline;
    margin-top:-2px;
    margin-left: -6px;
    border-radius: 0px;
    height: 42px;
    width: 60px;
    font-size: $global_fontSize_default;
    font-weight:bold;
}
#deptFootNewsWrapper button:hover, .newsletterSignup button:hover {
    background-color: $global_color_black;
}

/*Department Body
______________________________________________________________________*/

.department {
    .colorBlock {
        .mainColumnTwoColumn, .accords {
            a:not(.btn) {
                font-weight: bold;
    
                &:hover {
                    background: $global_color_primaryBlue;
                    color: $global_color_white;
                    text-decoration: none;
                }
            }
        }
    }
}

/*Department Left Column
______________________________________________________________________*/
.department #RightCol,
.department #leftCol {
    padding-bottom: 150px;
}
.department #leftCol .SidebarXHTMLCopyComponent div,
.department #leftCol .SidebarXHTMLCopyComponent span,
.department #leftCol .SidebarXHTMLCopyComponent p{
    max-width:250px;
}

.department #leftCol h2 {
    margin-top:60px;
}

.department #leftCol select {
    max-width:75%;
}

.department #leftCol .TrumbaCalendarComponent {
    max-width: 85%;
}

/*Department Right Column
______________________________________________________________________*/
.department #rightCol:before {
    /*display:block;
    content:"";
    width:75%;
    height:2px;
    border-top:8px solid $global_color_black;
    padding-bottom:20px;*/
}

.department #rightCol, .department #leftCol,
.topTier #rightCol, .topTier #leftCol,
.CityWideDetail #rightCol, .CityWideDetail #leftCol{
    h2 {
        border-bottom: 1px solid #dad9d6;
        font-size: 24px !important;
    }

    h3 {
        font-size: 18px !important;
    }

    h4 {
        font-size: 15px !important;
    }

    h5 {
        font-size: 12px !important;
    }

    .CityLinkRSSComponent h2 {
        border-bottom: none !important;
    }
    /*h2, h3, h4, h5{
        margin-top: 0px !important;
    }*/
}

.department #rightCol .ContactComponent,
.department #rightCol .SidebarLinkListComponent,
.department #rightCol .SidebarXHTMLCopyComponent,
.department #rightCol .TrumbaCalendarComponent{
    padding-bottom: 40px;
}
/*Right Column Forms*/
.department #rightCol {
    padding-left: 20px !important;
    padding-right: 0px !important;
}
.department #rightCol input{
    width:100%;
    height:40px;
}
.department #rightCol  a .btn,
.department #rightCol  .btn{
    border-radius: 2px;
    border: none;
    margin-top: 5px;
    padding-top: 10px;
    height: 40px;
    font-weight: bold;
    line-height: 20px;
}
.department #rightCol a .btn:hover,
.department #rightCol .btn:hover{
    background: $global_color_black;
    color: $global_color_white;
}
.department #rightCol .SidebarLinkListComponent.withBackgroundColor,
.department #rightCol .SidebarXHTMLCopyComponent.withBackgroundColor,
.department #rightCol .SidebarNavigationComponent.withBackgroundColor,
.department #leftCol .SidebarLinkListComponent.withBackgroundColor,
.department #leftCol .SidebarXHTMLCopyComponent.withBackgroundColor,
.department #leftCol .leftNav2.withBackgroundColor {
    background-color: #f1f1f1;
    //-padding: 10px inside gray box, 20 px space below gray box, no need for H2 underline
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px !important;

    h2 {
        border-bottom: none !important;
    }

    h2, h3, h4, h5 {
        margin-top: 0px !important;
    }
}
.department #rightCol .SidebarLinkListComponent,
.department #rightCol .SidebarXHTMLCopyComponent,
.department #rightCol .SidebarNavigationComponent,
.department #leftCol .SidebarLinkListComponent,
.department #leftCol .SidebarXHTMLCopyComponent,
.department #leftCol .leftNav2 {
    h2, h3, h4, h5 {
        margin-top: 0px !important;
    }
}

.department #rightCol .QuoteComponent,
.department #rightCol .SidebarImageComponent,
.department #leftCol .QuoteComponent,
.department #leftCol .SidebarImageComponent {
    padding-bottom: 40px !important;
}
.department {
    #rightCol {
        .SidebarImageComponent {
            .sidebarImageLink {
                line-height: 25px;
            }
        }
    }
}
.QuoteComponent {
    margin-top: 60px;
}
.QuoteComponent .pq_image {
    margin-bottom: 10px;
}
.QuoteComponent .quoteImageContainer {
    margin: 10px 0;
    border: solid $global_color_lightGray 1px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    width: 310px;
    margin: auto;
}
.QuoteComponent .quoteImageContainer .quoteImage {
    width: 310px;
    height: 200px;
    background-position: center center;
    background-size: cover;
}

/*Contact Component - In Sidebars*/
aside .contactTitle_Tagline, #mainColMain .contactTitle_Tagline,
#mainColMain .contactTilePhone, #mainColMain .contactTileEmail,
aside .contactRole, #mainColMain .contactRole{
    margin-bottom: 10px;
}
aside .contactTileContentBlock, .departmentContactsContainer .contactTileContentBlock,
#mainColMain .contactTileContentBlock {
    margin:0px;
}
aside .contactTilePhysicalAddress, 
aside .contactTileMailingAddress {
    border-top:1px dashed $global_color_lightGray;
    margin-left:1px;
    padding:15px 0px 0px 0px;
}
aside .phoneMain,
aside .contactPageLink,
aside .mailingAddress,
aside .physicalAddress  {
    margin-top:20px;
}
aside .socialMediaItems a img{
    display:inline;
    margin:20px 0px;
}

/* Contact Component MainCol */
#mainColMain .departmentContactsContainer .contactTileContentBlock div {
    margin-bottom: 0px;
}

@media (max-width: 767px) {
    .department #leftCol {
        padding-bottom: 0px;
    }
}